import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Icons from '../Icons';
import { CtaButtonPlaceholder } from './placeholders';

import loadComponents from '../Loadable';
import ResponsiveContext from '../Responsive/context';

const Button = loadComponents('button');

export default function CtaButton({ size, alt }) {
  const { width } = useContext(ResponsiveContext);
  const data = useStaticQuery(graphql`
    query CtaButtonQuery {
      wp {
        ...CallToActions
      }
    }
  `);

  const { buttonLink, buttonText } =
    data.wp.callToAction.callToAction.callToAction;

  return (
    <Button
      className="ctaBtn"
      to={buttonLink}
      label={buttonText}
      primary={!alt}
      secondary={alt}
      fallback={<CtaButtonPlaceholder size={size} />}
      style={{ ...buttonStyle, width: size || `310px` }}
    >
      <Icons.Calculator
        size="22px"
        style={{ marginRight: `15px`, display: width < 420 && `none` }}
      />
      <span style={textStyle}>{buttonText}</span>
    </Button>
  );
}

const textStyle = {
  fontFamily: `Corinthian Bold, sans-serif`,
  fontSize: `27px`,
  lineHeight: 1,
};

const buttonStyle = {
  borderRadius: `100px`,
  padding: `20px 45px`,
};
